<template>
    <div class="layout">
        <img src="@/assets/logo.png" alt="Premium Testing CMS" class="logo" />
        <div class="login">
            <form @submit.prevent="checkAuth">
                <Input v-model="user.name" type="text" label="Gebruikersnaam" :errorClass="errorClass" />
                <Input v-model="user.password" type="password" label="Wachtwoord" :errorClass="errorClass" />

                <div class="row">
                    <span class="error color-error" v-if="error">{{
                        error == 401 ? 'De inloggegevens zijn incorrect' : 'Oops! er ging iets fout'
                    }}</span>
                </div>

                <div class="row">
                    <Button :submit="true">Inloggen</Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { api } from '@/api'
import Button from '@/components/Button.vue'
import Input from '@/components/inputs/Textfield.vue'

export default {
    name: 'Login',

    components: {
        Button,
        Input,
    },

    data() {
        return {
            user: {
                name: '',
                password: '',
            },
            error: '',
            errorClass: false,
        }
    },

    methods: {
        checkAuth() {
            this.error = ''
            this.errorClass = false
            api.post('/auth', { authData: this.user })
                .then(response => {
                    if (response.status == 200) {
                        localStorage.setItem('access_token', response.data.token)
                        localStorage.setItem('permission', response.data.permission)
                        this.$store.commit('SET_TOKEN', response.data.token)
                        this.$store.commit('SET_PERMISSION', response.data.permission)
                        this.$router.push('/afspraak-overzicht').catch(() => {})
                    }
                })
                .catch(error => {
                    this.throwErr(error.response.status)
                })
        },

        throwErr(statusCode) {
            this.error = statusCode
            this.errorClass = true
        },
    },
}
</script>

<style lang="scss" scoped>
.layout {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logo {
    width: 150px;
    margin: 0 auto 24px;
    display: block;
}

.login {
    background: $color-white;
    max-width: 400px;
    width: 100%;
    border-radius: 12px;
    padding: 24px;
    margin: 0 auto;
    box-shadow: 0px 0px 10px rgba($color-maroon, 0.2);

    form {
        width: 100%;

        .row {
            width: 100%;
            display: block;
            margin-bottom: $spacer;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
</style>

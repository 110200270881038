<template>
    <div class="add-company">
        <!-- <Modal>{{ modalMessage }}</Modal> -->

        <div class="container">
            <div class="input__rows stretch">
                <div>
                    <h2>Afspraak wijzigen</h2>
                    <p>Pas hieronder de gegevens van de afspraak aan</p>

                    <div>
                        <div class="column-3">
                            <Input
                                v-model="formData.afspraak.voornaam"
                                type="text"
                                label="Voornaam"
                                :errorClass="errors.voornaam"
                                :errorMessage="errors.voornaam ? 'Vul de voornaam in' : ''"
                                :disabled="success ? true : false"
                            />
                            <Input
                                v-model="formData.afspraak.achternaam"
                                type="text"
                                label="Achternaam"
                                :errorClass="errors.achternaam"
                                :errorMessage="errors.achternaam ? 'Vul de achternaam in' : ''"
                                :disabled="success ? true : false"
                            />
                            <Input
                                v-model="formData.afspraak.geboortedatum"
                                type="text"
                                label="Geboortedatum"
                                :errorClass="errors.geboortedatum"
                                :errorMessage="errors.geboortedatum ? 'Vul de geboortedatum in' : ''"
                                :disabled="success ? true : false"
                            />
                        </div>
                        <div class="column-3">
                            <Input
                                v-model="formData.afspraak.telefoon"
                                type="text"
                                label="Telefoonnummer"
                                :errorClass="errors.telefoon"
                                :errorMessage="errors.telefoon ? 'Vul het telefoonnummer in' : ''"
                                :disabled="success ? true : false"
                            />
                            <Input
                                v-model="formData.afspraak.email"
                                type="text"
                                label="Email"
                                :errorClass="errors.email"
                                :errorMessage="errors.email ? 'Vul de email in' : ''"
                                :disabled="success ? true : false"
                            />
                            <Input
                                v-model="formData.afspraak.datum"
                                type="text"
                                label="Test datum"
                                :errorClass="errors.datum"
                                :errorMessage="errors.datum ? 'Vul de test datum in' : ''"
                                :disabled="success ? true : false"
                            />
                            <Input
                                v-model="formData.afspraak.tijd"
                                type="text"
                                label="Test tijdstip"
                                :errorClass="errors.tijd"
                                :errorMessage="errors.tijd ? 'Vul het tijdstip in' : ''"
                                :disabled="success ? true : false"
                            />
                        </div>

                        <div class="footer">
                            <Button @click.native="removeAppointment" :error="true">Verwijder afspraak</Button> &nbsp;
                            <Button @click.native="updateAppointment" :disabled="success ? true : false"
                                >Wijzig Afspraak</Button
                            >
                        </div>

                        <span class="success" v-if="success">{{ success }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import Input from '@/components/inputs/Textfield.vue'
import Textarea from '@/components/inputs/Textarea.vue'
import { api } from '@/api'

export default {
    name: 'Afspraak-Bewerken',

    components: {
        Modal,
        Input,
        Textarea,
        Button,
    },

    data() {
        return {
            hourRegex: /[0-9:]+/g,
            success: '',
            errors: {
                voornaam: false,
                achternaam: false,
                geboortedatum: false,
                email: false,
                telefoon: false,
                datum: false,
                tijd: false,
            },
            formData: {
                afspraak: {
                    voornaam: '',
                    achternaam: '',
                    geboortedatum: '',
                    email: '',
                    telefoon: '',
                    datum: '',
                    tijd: '',
                },
            },
        }
    },

    async mounted() {
        await this.getAppointment()
    },

    methods: {
        async getAppointment() {
            try {
                const response = await api.get('/getAppointment', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                        Permission: localStorage.getItem('permission'),
                        id: this.$route.params.id,
                    },
                })

                const result = await response.data
                this.formData.afspraak.voornaam = result.naam
                this.formData.afspraak.achternaam = result.achternaam
                this.formData.afspraak.geboortedatum = result.geboortedatum
                this.formData.afspraak.datum = result.datum
                this.formData.afspraak.tijd = result.tijd
                this.formData.afspraak.email = result.email
                this.formData.afspraak.telefoon = result.telnr
            } catch (error) {
                console.log(error)
            }
        },

        async updateAppointment() {
            if (this.success) return

            await this.checkErrors()
            for (const err of Object.entries(this.errors)) {
                console.log(err[1])
                if (err[1]) return
            }

            try {
                const response = await api.post(
                    '/updateAppointment',
                    {
                        appointment: this.formData.afspraak,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            Permission: localStorage.getItem('permission'),
                            id: this.$route.params.id,
                        },
                    }
                )
                const result = await response.data
                this.success = result
            } catch (error) {
                console.log(error)
            }
        },

        async removeAppointment() {
            try {
                const response = await api.post(
                    '/removeAppointment',
                    {
                        id: this.$route.params.id,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            Permission: localStorage.getItem('permission'),
                        },
                    }
                )
                const result = await response.data
                this.success = result
            } catch (error) {
                console.log(error)
            }
        },

        async checkErrors() {
            if (!this.formData.afspraak.voornaam) this.errors.voornaam = true
            if (!this.formData.afspraak.achternaam) this.errors.achternaam = true
            if (!this.formData.afspraak.geboortedatum) this.errors.geboortedatum = true
            if (!this.formData.afspraak.datum) this.errors.datum = true
            if (!this.formData.afspraak.tijd) this.errors.tijd = true
            if (!this.formData.afspraak.email) this.errors.email = true
            if (!this.formData.afspraak.telefoon) this.errors.telefoon = true
        },
    },

    watch: {
        'formData.afspraak': {
            deep: true,
            handler(data) {
                Object.keys(this.errors).forEach(key => (this.errors[key] = false))
            },
        },
    },
}
</script>

<style lang="scss">
.add-company {
    .container {
        background: $color-white;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        padding: 24px;
        box-shadow: 0px 0px 10px rgba($color-maroon, 0.2);

        .input__rows {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 24px;

            &.stretch {
                display: block;
            }
        }
    }
}

.column-3 {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(2, 1fr) minmax(150px, 250px);
    gap: $spacer;
}

.column-4 {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 150px 50px 150px 1fr;
    gap: $spacer;
    align-items: center;
}

.footer {
    margin: $spacer 0;
    display: flex;
    justify-content: flex-end;
    gap: $spacer;
}

h1,
h2 {
    font-size: rem(24px);
    font-weight: $semi-bold;
    color: $color-burgundy;
    margin: 0 0 24px;
}
</style>

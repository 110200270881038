<template>
    <div class="add-company">
        <!-- <Modal>{{ modalMessage }}</Modal> -->

        <h1>Locatie toevoegen</h1>

        <div class="container">
            <div class="input__rows stretch">
                <div>
                    <h2>Locatie toevoegen</h2>

                    <div>
                        <div class="column-3">
                            <Input
                                v-model="formData.locatie.locatieNaam"
                                type="text"
                                label="Locatie naam"
                                :errorClass="errors.locatieNaam"
                                :errorMessage="errors.locatieNaam ? 'Vul uw locatienaam in' : ''"
                                placeholder="Bijv; Nieuw-Vennep"
                            />
                            <Input
                                v-model="formData.locatie.adres"
                                type="text"
                                label="Adres"
                                :errorClass="errors.adres"
                                :errorMessage="errors.adres ? 'Vul uw adres in' : ''"
                                placeholder="Het volledige adres"
                            />
                            <Input
                                v-model="formData.locatie.maxTests"
                                type="number"
                                label="Max. aantal testen per 15min"
                            />
                        </div>

                        <hr />

                        <h2>Openingstijden</h2>
                        <p>
                            Wanneer je op een dag geen tijden toevoegt zal de locatie op die dag gesloten zijn.
                        </p>

                        <div class="column-4" v-for="(dag, i) in formData.locatie.openingsTijden" :key="i">
                            <Input
                                v-model="dag.start"
                                type="text"
                                :label="dag.dag"
                                placeholder="08:00"
                                :errorClass="dag.error"
                                :errorMessage="dag.error ? 'format HH:mm' : ''"
                                :disabled="dag.gesloten"
                            />
                            <span>Tot</span>
                            <Input
                                v-model="dag.end"
                                type="text"
                                label="-"
                                placeholder="18:00"
                                :errorClass="dag.error"
                                :errorMessage="dag.error ? 'format HH:mm' : ''"
                                :disabled="dag.gesloten"
                            />

                            <div>
                                <label :for="dag.gesloten">
                                    <input
                                        type="checkbox"
                                        :name="dag.gesloten"
                                        :id="dag.gesloten"
                                        v-model="dag.gesloten"
                                    />
                                    Gesloten</label
                                >
                            </div>
                        </div>

                        <hr />
                        <h2>Bedanktpagina</h2>
                        <p>
                            Ruimte om tekst en/of regels te communiceren.
                        </p>

                        <Textarea
                            v-model="formData.locatie.bedanktpagina"
                            type="text"
                            placeholder="Deze tekst wordt getoond op de bedankt pagina nadat een werknemer een afspraak heeft gepland."
                            :errorClass="errors.bedanktpagina"
                            :errorMessage="errors.bedanktpagina ? 'Dit veld mag niet leeg zijn' : ''"
                        />

                        <div class="footer">
                            <Button @click.native="createLocation" icon="check-circle">Locatie toevoegen</Button>
                        </div>

                        <span class="success" v-if="success">{{ success }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import Input from '@/components/inputs/Textfield.vue'
import Textarea from '@/components/inputs/Textarea.vue'
import { api } from '@/api'

export default {
    name: 'Locatie Toevoegen',

    components: {
        Modal,
        Input,
        Textarea,
        Button,
    },

    data() {
        return {
            hourRegex: /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/g,
            success: '',
            errors: {
                locatieNaam: false,
                adres: false,
                bedanktpagina: false,
            },
            formData: {
                locatie: {
                    locatieNaam: '',
                    adres: '',
                    maxTests: 0,
                    bedanktpagina: '',
                    openingsTijden: [
                        {
                            dag: 'maandag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                        {
                            dag: 'dinsdag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                        {
                            dag: 'woensdag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                        {
                            dag: 'donderdag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                        {
                            dag: 'vrijdag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                        {
                            dag: 'zaterdag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                        {
                            dag: 'zondag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                    ],
                },
            },
        }
    },

    methods: {
        async createLocation() {
            if (this.success) return

            this.formData.locatie.openingsTijden.forEach(openingsTijd => {
                if (!openingsTijd.start || !openingsTijd.end) openingsTijd.gesloten = true
                if (
                    !openingsTijd.start.match(this.hourRegex) &&
                    !openingsTijd.end.match(this.hourRegex) &&
                    !openingsTijd.gesloten
                )
                    openingsTijd.error = true
            })

            await this.checkErrors()
            for (const err of Object.entries(this.errors)) {
                console.log(err[1])
                if (err[1]) return
            }

            try {
                const response = await api.post(
                    '/createLocation',
                    {
                        locatie: this.formData.locatie,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            Permission: localStorage.getItem('permission'),
                        },
                    }
                )
                const result = await response.data
                this.success = result
            } catch (error) {
                console.log(error)
            }
        },

        async checkErrors() {
            if (!this.formData.locatie.locatieNaam) this.errors.locatieNaam = true
            if (!this.formData.locatie.adres) this.errors.adres = true
            if (!this.formData.locatie.bedanktpagina) this.errors.bedanktpagina = true
        },
    },

    // watch: {
    //     'formData.locatie': {
    //         deep: true,
    //         handler(data) {
    //             Object.keys(this.errors).forEach(key => (this.errors[key] = false))
    //         },
    //     },
    // },
}
</script>

<style lang="scss">
.add-company {
    .container {
        background: $color-white;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        padding: 24px;
        box-shadow: 0px 0px 10px rgba($color-maroon, 0.2);

        .input__rows {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 24px;

            &.stretch {
                display: block;
            }
        }
    }
}

.column-3 {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(2, 1fr) minmax(150px, 250px);
    gap: $spacer;
}

.column-4 {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 150px 50px 150px 1fr;
    gap: $spacer;
    align-items: center;
}

.footer {
    margin: $spacer 0;
    display: flex;
    justify-content: flex-end;
}

h1,
h2 {
    font-size: rem(24px);
    font-weight: $semi-bold;
    color: $color-burgundy;
    margin: 0 0 24px;
}
</style>

<template>
    <transition name="fade">
        <div class="backdrop" v-if="modalState">
            <div class="modal">
                <div class="close" @click="updateModalState(false)">&times;</div>
                <div><slot>If this text is showing, something is wrong. Close the modal</slot></div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Modal',

    methods: {
        updateModalState(e) {
            this.$store.commit('SET_MODALSTATE', e)
        },
    },

    computed: {
        modalState() {
            return this.$store.state.showModal
        },
    },
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.backdrop {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.modal {
    background: $color-white;
    min-width: 300px;
    max-width: 500px;
    min-height: 150px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: $spacer * 2;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .close {
        position: absolute;
        right: 10px;
        top: 0px;
        font-size: rem(30px);
        font-weight: $bold;
        color: $color-maroon;
        cursor: pointer;
    }

    h1,
    h2,
    h3,
    h4 {
        margin: 0;
    }

    p {
        margin: $spacer * 2 0;
    }
}
</style>

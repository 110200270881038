import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    token: '',
    permission: null,
    date: {
        date: '',
        time: '',
    },
    locations: [
        {
            id: 1,
            text: 'Rooswijck 5, 1081 AJ Amsterdam.',
            city: 'Amsterdam',
        },
        {
            id: 2,
            text: 'Pondweg 7, 2153 PK Nieuw-Vennep.',
            city: 'Nieuw-Vennep',
        },
    ],
    activeLocation: 1,
    showModal: false,
    maxPerTime: null,
    companyTestCount: null,
    companyTestCountDct: null,
}

export const mutations = {
    SET_DATETIME(state, obj) {
        state.date = obj
    },

    SET_TOKEN(state, token) {
        state.token = token
    },

    SET_PERMISSION(state, val) {
        state.permission = val
    },

    SET_MODALSTATE(state, bool) {
        state.showModal = bool
    },

    SET_LOCATION(state, id) {
        state.activeLocation = id
    },

    SET_MAXPERTIME(state, num) {
        state.maxPerTime = num
    },

    SET_COMPANYCOUNT(state, num) {
        state.companyTestCount = num
    },

    SET_COMPANYCOUNTDCT(state, num) {
        state.companyTestCountDct = num
    },
}

export const actions = {}

const store = new Vuex.Store({
    state,
    mutations,
    actions,
})

export default store

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueTippy, { TippyComponent } from 'vue-tippy'

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VCalendar)
Vue.use(VueTippy, {
    directive: 'tippy', // => v-tippy
    flipDuration: 0,
    popperOptions: {
        modifiers: {
            preventOverflow: {
                enabled: false,
            },
            hide: { enabled: false },
        },
    },
})
Vue.component('tippy', TippyComponent)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')

<template>
    <div class="accreditatie">
        <!-- <Modal>{{ modalMessage }}</Modal> -->

        <div class="page__header">
            <h1>Accreditatie</h1>
        </div>

        <div class="accreditatie__input">
            <Input
                v-model="searchModel"
                type="text"
                placeholder="Scan de test barcode (orderid)"
                :maxLength="12"
                :errorClass="error"
                :errorMessage="errorMessage"
                :autoFocus="true"
            />
            <Button icon="book" @click.native="searchOrder(searchModel)">Zoek order</Button>
        </div>

        <hr class="seperator" />

        <div class="container">
            <Table
                :headings="[
                    { key: 'naam', label: 'Naam' },
                    { key: 'achternaam', label: 'Achternaam' },
                    { key: 'geboortedatum', label: 'Geboortedatum' },
                    { key: 'datum', label: 'Test datum' },
                    { key: 'tijd', label: 'Tijdstip' },
                ]"
                :data="orderBook"
                :withUtils="true"
                defaultValue="---"
            >
                <template v-slot:utils>
                    <tippy interactive arrow distant="7" interactiveBorder="7" style="display: flex">
                        <template v-slot:trigger>
                            <Button icon="print" :small="true">
                                Custom label
                            </Button>
                        </template>

                        <div>
                            <Input
                                v-model="customLabel"
                                :small="true"
                                :maxLength="12"
                                type="text"
                                placeholder="6-12 karakters"
                            />
                        </div>
                    </tippy>

                    <br />

                    <Button
                        icon="print"
                        :small="true"
                        @click.native="generateBarcode($event, customLabel ? customLabel : searchModel)"
                        >{{ success ? 'Aangemaakt' : 'Genereer barcode' }}</Button
                    >
                </template>
            </Table>

            <div class="barcode__wrapper" v-if="barcodePath">
                <img class="barcode__image" :src="barcodePath" id="barcode" />
                <Button icon="print" @click.native="printBarcode">Print barcode</Button>
            </div>
        </div>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Input from '@/components/inputs/Textfield.vue'
import Button from '@/components/Button.vue'
import Table from '@/components/Table.vue'
import { api } from '@/api'

import printJS from 'print-js'

export default {
    name: 'Accreditatie',

    components: {
        Modal,
        Input,
        Button,
        Table,
    },

    data() {
        return {
            searchModel: '',
            customLabel: '',
            error: false,
            errorMessage: '',
            success: false,
            barcodePath: '',
            orderBook: [],
        }
    },

    methods: {
        async searchOrder(id) {
            this.error = false
            this.errorMessage = ''

            if (!id.length || id.length < 6 || id.length > 12) {
                this.errorMessage = 'Dit is geen geldig order id'
                this.error = true
                return
            }

            try {
                const response = await api.get('/fetchOrder', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                        Permission: localStorage.getItem('permission'),
                        hashedid: id,
                    },
                })

                const result = await response.data.data
                this.orderBook = [result]
            } catch (error) {
                this.error = true
                this.errorMessage = 'OrderID niet gevonden'
                return
            }
        },

        async setCustomLabel(id) {
            try {
                const response = await api.post(
                    '/setCustomLabel',
                    { hash: this.searchModel, customlabel: id },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            Permission: localStorage.getItem('permission'),
                        },
                    }
                )
                const result = await response
                console.log(result)
            } catch (error) {
                return console.log(error)
            }
        },

        async generateBarcode(e, id) {
            if (this.customLabel) await this.setCustomLabel(this.customLabel)

            try {
                const response = await api.post(
                    '/createBarCode',
                    { hash: id },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            Permission: localStorage.getItem('permission'),
                        },
                    }
                )
                const result = await response

                if (result.status == 200) {
                    this.barcodePath = `barcodes/${result.data.path}`
                    this.success = true
                }
            } catch (error) {
                return console.log(error)
            }
        },

        async printBarcode() {
            printJS(this.barcodePath, 'image')
        },
    },
}
</script>

<style lang="scss">
.accreditatie {
    &__input {
        max-width: 400px;
        width: 100%;
        margin-top: $spacer;
        display: grid;
        grid-template-columns: 1fr auto;
        gap: $spacer;
        align-items: flex-start;

        .input__wrapper {
            margin-top: 0 !important;
        }
    }

    .container {
        background: $color-white;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        padding: 24px;
        box-shadow: 0px 0px 10px rgba($color-maroon, 0.2);

        .input__rows {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 24px;
        }

        .span__util {
            transition: color 0.2s ease-in-out;

            &.success {
                color: $color-white;
            }

            &:hover {
                color: $color-tangerine;
                transition: color 0.2s ease-in-out;
            }
        }

        .barcode__wrapper {
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: $spacer;
            margin: $spacer * 2 auto 0;

            .barcode__image {
                max-width: 100%;
            }
        }
    }

    hr {
        margin: 48px auto;
    }
}

h1,
h2 {
    font-size: rem(24px);
    font-weight: $semi-bold;
    color: $color-burgundy;
    margin: 0 0 24px;
}
</style>

<template>
    <div class="add-company">
        <!-- <Modal>{{ modalMessage }}</Modal> -->

        <div class="container">
            <div class="input__rows stretch">
                <div>
                    <h2>Bedrijfslocatie Updaten</h2>
                    <p>Pas hieronder de gegevens van uw locatie aan</p>

                    <div>
                        <div class="column-3">
                            <Input
                                v-model="formData.locatie.locatieNaam"
                                type="text"
                                label="Locatie naam"
                                :errorClass="errors.locatieNaam"
                                :errorMessage="errors.locatieNaam ? 'Vul uw locatienaam in' : ''"
                                placeholder="Bijv; Nieuw-Vennep"
                                :disabled="success ? true : false"
                            />
                            <Input
                                v-model="formData.locatie.adres"
                                type="text"
                                label="Adres"
                                :errorClass="errors.adres"
                                :errorMessage="errors.adres ? 'Vul uw adres in' : ''"
                                placeholder="Het volledige adres"
                                :disabled="success ? true : false"
                            />
                            <Input
                                v-model="formData.locatie.maxTests"
                                type="number"
                                label="Max. aantal testen per 15min"
                                :disabled="success ? true : false"
                            />
                        </div>

                        <hr />

                        <h2>Openingstijden</h2>
                        <p>
                            Wanneer je op een dag geen tijden toevoegt zal de locatie op die dag gesloten zijn.
                        </p>

                        <div class="column-4" v-for="(dag, i) in formData.locatie.openingsTijden" :key="i">
                            <Input
                                v-model="dag.start"
                                type="text"
                                :label="dag.dag"
                                placeholder="08:00"
                                :errorClass="dag.error"
                                :errorMessage="dag.error ? 'format HH:mm' : ''"
                                :disabled="dag.gesloten || success ? true : false"
                            />
                            <span>Tot</span>
                            <Input
                                v-model="dag.end"
                                type="text"
                                label="-"
                                placeholder="18:00"
                                :errorClass="dag.error"
                                :errorMessage="dag.error ? 'format HH:mm' : ''"
                                :disabled="dag.gesloten || success ? true : false"
                            />

                            <div>
                                <label :for="dag.gesloten">
                                    <input
                                        type="checkbox"
                                        :name="dag.gesloten"
                                        :id="dag.gesloten"
                                        v-model="dag.gesloten"
                                    />
                                    Gesloten</label
                                >
                            </div>
                        </div>

                        <hr />

                        <h2>Speciale Openingstijden Toevoegen</h2>
                        <p>
                            Gebruik deze functie om op een specifieke datum de openingstijden aan te passen. <br />
                            Wanneer je op een dag geen tijden toevoegt zal de locatie op die dag gesloten zijn.
                        </p>

                        <div
                            class="column-5"
                            v-for="(datum, i) in formData.locatie.specialeOpeningstijden"
                            :key="i + '-'"
                        >
                            <v-date-picker v-model="datum.datum" :masks="masks" :min-date="new Date()">
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="input__wrapper">
                                        <label for="date">Datum</label>
                                        <input
                                            id="date"
                                            class="input-text"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                            placeholder="DD/MM/YYYY"
                                        />
                                    </div>
                                </template>
                            </v-date-picker>
                            <Input
                                v-model="datum.start"
                                type="text"
                                label="Openingstijden"
                                placeholder="09:00"
                                :errorClass="datum.error"
                                :errorMessage="datum.error ? 'format HH:mm' : ''"
                                :disabled="datum.gesloten || success ? true : false"
                            />
                            <span>Tot</span>
                            <Input
                                v-model="datum.end"
                                type="text"
                                label="-"
                                placeholder="18:00"
                                :errorClass="datum.error"
                                :errorMessage="datum.error ? 'format HH:mm' : ''"
                                :disabled="datum.gesloten || success ? true : false"
                            />

                            <div>
                                <label :for="datum.gesloten">
                                    <input
                                        type="checkbox"
                                        :name="datum.gesloten"
                                        :id="datum.gesloten"
                                        v-model="datum.gesloten"
                                    />
                                    Gesloten</label
                                >
                            </div>
                        </div>

                        <br />
                        <Button @click.native="specialeOpeningstijden" :disabled="success ? true : false"
                            >Openingstijd opslaan</Button
                        >

                        <hr />
                        <h2>Actieve Speciale Openingstijden</h2>
                        <Table
                            :headings="[
                                { key: 'datum', label: 'Datum' },
                                { key: 'start', label: 'Open' },
                                { key: 'end', label: 'Sluit' },
                            ]"
                            :data="formData.locatie.filledSpecialeOpeningstijden"
                            defaultValue="---"
                        >
                        </Table>

                        <hr />
                        <h2>Bedanktpagina</h2>
                        <p>
                            Ruimte om tekst en/of regels te communiceren.
                        </p>

                        <Textarea
                            v-model="formData.locatie.bedanktpagina"
                            type="text"
                            placeholder="Deze tekst wordt getoond op de bedankt pagina nadat een werknemer een afspraak heeft gepland."
                            :errorClass="errors.bedanktpagina"
                            :errorMessage="errors.bedanktpagina ? 'Dit veld mag niet leeg zijn' : ''"
                            :disabled="success ? true : false"
                        />

                        <div class="footer">
                            <Button @click.native="updateLocation" :disabled="success ? true : false"
                                >Update Locatie</Button
                            >
                        </div>

                        <span class="success" v-if="success">{{ success }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import Input from '@/components/inputs/Textfield.vue'
import Textarea from '@/components/inputs/Textarea.vue'
import Table from '@/components/Table.vue'
import { api } from '@/api'

export default {
    name: 'Locatie-Updaten',

    components: {
        Modal,
        Input,
        Textarea,
        Button,
        Table,
    },

    data() {
        return {
            masks: {
                input: 'DD/MM/YYYY',
            },
            hourRegex: /[0-9:]+/g,
            success: '',
            errors: {
                locatieNaam: false,
                adres: false,
                bedanktpagina: false,
            },
            formData: {
                locatie: {
                    locatieNaam: '',
                    adres: '',
                    maxTests: 0,
                    bedanktpagina: '',
                    openingsTijden: [
                        {
                            dag: 'maandag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                        {
                            dag: 'dinsdag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                        {
                            dag: 'woensdag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                        {
                            dag: 'donderdag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                        {
                            dag: 'vrijdag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                        {
                            dag: 'zaterdag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                        {
                            dag: 'zondag',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                    ],
                    specialeOpeningstijden: [
                        {
                            datum: '',
                            start: '',
                            end: '',
                            gesloten: false,
                            error: false,
                        },
                    ],
                    filledSpecialeOpeningstijden: [],
                },
            },
            template: {
                datum: '',
                start: '',
                end: '',
                gesloten: false,
                error: false,
            },
        }
    },

    async mounted() {
        await this.getLocation()
    },

    methods: {
        async getLocation() {
            try {
                const response = await api.get('/getLocation', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                        Permission: localStorage.getItem('permission'),
                        location: this.$route.params.id,
                    },
                })

                const result = await response.data
                this.formData.locatie.locatieNaam = result.locatie.locatienaam
                this.formData.locatie.adres = result.locatie.adres
                this.formData.locatie.maxTests = result.locatie.maxtests
                this.formData.locatie.bedanktpagina = result.locatie.bedanktpagina

                result.specialeOpeningstijden.forEach(date => {
                    this.formData.locatie.filledSpecialeOpeningstijden.push(date)
                })

                this.formData.locatie.openingsTijden[0].start = result.openingsTijden[0].maandag[0]
                this.formData.locatie.openingsTijden[0].end = result.openingsTijden[0].maandag[1]
                this.formData.locatie.openingsTijden[1].start = result.openingsTijden[1].dinsdag[0]
                this.formData.locatie.openingsTijden[1].end = result.openingsTijden[1].dinsdag[1]
                this.formData.locatie.openingsTijden[2].start = result.openingsTijden[2].woensdag[0]
                this.formData.locatie.openingsTijden[2].end = result.openingsTijden[2].woensdag[1]
                this.formData.locatie.openingsTijden[3].start = result.openingsTijden[3].donderdag[0]
                this.formData.locatie.openingsTijden[3].end = result.openingsTijden[3].donderdag[1]
                this.formData.locatie.openingsTijden[4].start = result.openingsTijden[4].vrijdag[0]
                this.formData.locatie.openingsTijden[4].end = result.openingsTijden[4].vrijdag[1]
                this.formData.locatie.openingsTijden[5].start = result.openingsTijden[5].zaterdag[0]
                this.formData.locatie.openingsTijden[5].end = result.openingsTijden[5].zaterdag[1]
                this.formData.locatie.openingsTijden[6].start = result.openingsTijden[6].zondag[0]
                this.formData.locatie.openingsTijden[6].end = result.openingsTijden[6].zondag[1]
            } catch (error) {
                console.log(error)
            }
        },

        async updateLocation() {
            if (this.success) return

            this.formData.locatie.openingsTijden.forEach(openingsTijd => {
                if (!openingsTijd.start || !openingsTijd.end) openingsTijd.gesloten = true

                if (
                    !openingsTijd.start.match(this.hourRegex) &&
                    !openingsTijd.end.match(this.hourRegex) &&
                    !openingsTijd.gesloten
                )
                    openingsTijd.error = true

                if (openingsTijd.gesloten) {
                    openingsTijd.start = ''
                    openingsTijd.end = ''
                }
            })

            await this.checkErrors()
            for (const err of Object.entries(this.errors)) {
                console.log(err[1])
                if (err[1]) return
            }

            try {
                const response = await api.post(
                    '/updateLocation',
                    {
                        locatie: this.formData.locatie,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            Permission: localStorage.getItem('permission'),
                            locationID: this.$route.params.id,
                        },
                    }
                )
                const result = await response.data
                this.success = result
            } catch (error) {
                console.log(error)
            }
        },

        async specialeOpeningstijden() {
            if (this.success) return

            this.formData.locatie.specialeOpeningstijden.forEach(openingsTijd => {
                if (!openingsTijd.start || !openingsTijd.end) openingsTijd.gesloten = true

                if (
                    !openingsTijd.start.match(this.hourRegex) &&
                    !openingsTijd.end.match(this.hourRegex) &&
                    !openingsTijd.gesloten
                )
                    openingsTijd.error = true

                if (openingsTijd.gesloten) {
                    openingsTijd.start = ''
                    openingsTijd.end = ''
                }
            })

            this.formData.locatie.specialeOpeningstijden[0].datum = new Date(
                this.formData.locatie.specialeOpeningstijden[0].datum
            ).toLocaleDateString('en-GB')

            await this.checkErrors()
            for (const err of Object.entries(this.errors)) {
                console.log(err[1])
                if (err[1]) return
            }

            try {
                const response = await api.post(
                    '/specialeOpeningstijden',
                    { openingsTijden: this.formData.locatie.specialeOpeningstijden },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            Permission: localStorage.getItem('permission'),
                            locationID: this.$route.params.id,
                        },
                    }
                )

                const result = await response.data
                this.success = result
            } catch (error) {}
        },

        async checkErrors() {
            if (!this.formData.locatie.locatieNaam) this.errors.locatieNaam = true
            if (!this.formData.locatie.adres) this.errors.adres = true
            if (!this.formData.locatie.bedanktpagina) this.errors.bedanktpagina = true
        },
    },

    watch: {
        'formData.locatie': {
            deep: true,
            handler(data) {
                Object.keys(this.errors).forEach(key => (this.errors[key] = false))
            },
        },
    },
}
</script>

<style lang="scss">
.input__wrapper {
    margin-top: 24px;
}

.add-company {
    .container {
        background: $color-white;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        padding: 24px;
        box-shadow: 0px 0px 10px rgba($color-maroon, 0.2);

        .input__rows {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 24px;

            &.stretch {
                display: block;
            }
        }
    }
}

.column-3 {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(2, 1fr) minmax(150px, 250px);
    gap: $spacer;
}

.column-4 {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 150px 50px 150px 1fr;
    gap: $spacer;
    align-items: center;
}

.column-5 {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 200px 150px 50px 150px 1fr;
    gap: $spacer;
    align-items: center;

    .input-text {
        max-width: 400px;
        width: 100%;
        height: 46px;
        outline: 0;
        padding: 0 10px;
        color: $color-burgundy;
        border: 1px solid $color-border;
        border-radius: 6px;
        box-shadow: 0px 4px 5px rgba(57, 9, 35, 0.05);
        transition: border-color 0.2s;

        &:focus {
            border-color: darken($color-border, 20%);
            transition: border-color 0.2s;
        }

        &.error {
            color: $color-error;
            border-color: $color-error;
            transition: border-color 0.2s;
        }
    }

    label {
        font-size: rem(14px);
        font-weight: $semi-bold;
        color: $color-maroon;
        display: block;
        margin-bottom: 6px;
    }
}

.footer {
    margin: $spacer 0;
    display: flex;
    justify-content: flex-end;
}

h1,
h2 {
    font-size: rem(24px);
    font-weight: $semi-bold;
    color: $color-burgundy;
    margin: 0 0 24px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kalender"},[_vm._m(0),_c('hr',{staticClass:"seperator"}),_c('div',{staticClass:"container"},[_c('div',{staticStyle:{"margin-bottom":"50px"}},[_c('v-date-picker',{attrs:{"masks":_vm.masks},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"input__wrapper"},[_c('label',{attrs:{"for":"date"}},[_vm._v("Kies een dag")]),_c('input',_vm._g({staticClass:"input-text",attrs:{"id":"date"},domProps:{"value":inputValue}},inputEvents))])]}}]),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('Table',{attrs:{"headings":[
                { key: 'naam', label: 'Naam' },
                { key: 'achternaam', label: 'Achternaam' },
                { key: 'encryptedID', label: 'ID' },
                { key: 'geboortedatum', label: 'Geboortedatum' },
                { key: 'email', label: 'Email' },
                { key: 'telnr', label: 'Telefoon' },
                { key: 'datum', label: 'Test Datum' },
                { key: 'tijd', label: 'Tijdstip' },
                { key: 'productName', label: 'Product' } ],"data":_vm.appointments,"emptyMessage":_vm.error ? _vm.errorMessage : 'Er zijn geen afspraken gevonden',"route":true,"routeID":"encryptedID","routePath":"/afspraak","defaultValue":"---"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page__header"},[_c('h1',[_vm._v("Afspraken overzicht")])])}]

export { render, staticRenderFns }
<template>
    <div class="add-company">
        <!-- <Modal>{{ modalMessage }}</Modal> -->

        <div class="page__header">
            <h1>Locatie overzicht</h1>
            <div>
                <Input
                    v-model="searchModel"
                    v-on:keyup.backspace="filterLocaties(searchModel, $event)"
                    type="text"
                    placeholder="Locatie zoeken"
                    :error="error"
                />
                <Button link="locatie-toevoegen" icon="building">Locatie toevoegen</Button>
            </div>
        </div>

        <hr class="seperator" />

        <div class="container">
            <Table
                :headings="[
                    { key: 'locatienaam', label: 'Locatie' },
                    { key: 'adres', label: 'Adres' },
                ]"
                :data="locaties"
                :showArrayCount="true"
                :hoverLocations="true"
                :withUtils="false"
                :route="true"
                defaultValue="---"
            >
            </Table>
        </div>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Input from '@/components/inputs/Textfield.vue'
import Button from '@/components/Button.vue'
import Table from '@/components/Table.vue'
import { api } from '@/api'

export default {
    name: 'Locatie-Overzicht',

    components: {
        Modal,
        Input,
        Button,
        Table,
    },

    data() {
        return {
            searchModel: '',
            error: '',
            locaties: [],
            searchBackup: [],
        }
    },

    mounted() {
        this.fetchLocations()
    },

    methods: {
        async fetchLocations() {
            this.error = ''

            try {
                const response = await api.get('/fetchLocaties', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                        Permission: localStorage.getItem('permission'),
                    },
                })

                const result = await response.data.locaties

                this.locaties = result

                this.searchBackup = this.locaties
            } catch (error) {
                return (this.error = 'Er zijn geen locaties gevonden in de database...')
            }
        },

        filterLocaties(str, backspace) {
            if (!str || (backspace && str.length <= 3)) return (this.locaties = this.searchBackup)

            const matches = this.locaties.filter(x => {
                if (x.locatienaam.toLowerCase().indexOf(str) !== -1) {
                    return true
                }
            })

            this.locaties = matches
        },
    },

    watch: {
        searchModel(e) {
            this.filterLocaties(e, false)
        },
    },
}
</script>

<style lang="scss">
.add-company {
    .container {
        background: $color-white;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        padding: 24px;
        box-shadow: 0px 0px 10px rgba($color-maroon, 0.2);

        .input__rows {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 24px;
        }
    }

    hr {
        margin: 48px auto;
    }
}

h1,
h2 {
    font-size: rem(24px);
    font-weight: $semi-bold;
    color: $color-burgundy;
    margin: 0 0 24px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pmt__datatable",style:({ '--columns': _vm.headings.length })},[(_vm.data.length)?[_c('div',{class:['pmt__datatable__head', 'datatable__columns', { with__utils: _vm.withUtils }]},[_vm._l((_vm.headings),function(ref){
var label = ref.label;
return _c('div',{key:label},[_vm._v(_vm._s(label))])}),(_vm.withUtils)?_c('div',[_vm._v("Opties")]):_vm._e()],2),_vm._l((_vm.data),function(item,i){return _c('div',{key:i,class:[
                    'pmt__datatable__row',
                    'datatable__columns',
                    { with__utils: _vm.withUtils },
                    { uitslag: item.uitslag == 0 || item.uitslag == 1 || item.uitslag == 2 } ],on:{"click":function($event){_vm.route ? _vm.goTo((_vm.routePath + "/"), item, _vm.routeID) : ''}}},[_vm._l((_vm.headings),function(ref){
                var label = ref.label;
                var key = ref.key;
return [(typeof item[key] == 'string')?_c('div',{key:label},[_vm._v(" "+_vm._s(item[key])+" ")]):_vm._e(),(typeof item[key] == 'object')?_c('div',[(!_vm.hoverLocations)?_c('ul',[(_vm.showArrayCount)?_c('li',[_c('strong',[_vm._v(_vm._s(item[key].length))])]):_vm._e(),_vm._l((item[key]),function(value,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(value)+" ")])})],2):_c('ul',[(_vm.showArrayCount)?_c('li',[_c('strong',[_vm._v(_vm._s(item[key].length))])]):_vm._e(),_vm._l((item[key]),function(value,i){return _c('li',{key:i},[_c('router-link',{attrs:{"to":(_vm.routePath + "/" + (item.ID) + "/" + value)}},[_vm._v(_vm._s(value))])],1)})],2)]):_vm._e()]}),(_vm.withUtils)?_c('div',[_vm._t("utils")],2):_vm._e(),(_vm.hoverIcon)?_c('font-awesome-icon',{attrs:{"icon":"chevron-right"}}):_vm._e()],2)})]:_c('div',{staticClass:"pmt__datatable__empty-message"},[_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}}),_vm._v(" "+_vm._s(_vm.emptyMessage)+" ")],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <nav>
        <img src="@/assets/logo.png" alt="Premium Testing CMS" />
        <ul>
            <li v-for="item in routes" :key="item.name">
                <router-link :to="item.path">{{ item.name }}</router-link>
            </li>
        </ul>

        <div class="btn outline error" @click="logout">Uitloggen</div>
    </nav>
</template>

<script>
import router from '../router/index'

export default {
    name: 'Menu',
    methods: {
        logout() {
            localStorage.removeItem('access_token')
            this.$store.commit('SET_TOKEN', '')
            this.$router.push('/')
        },
    },

    computed: {
        routes() {
            const routesMeta = router.options.routes.filter(route => route.meta)
            return routesMeta.filter(x =>
                this.$store.state.permission == 2
                    ? this.$store.state.permission >= x.meta.requiresPermission && x.meta.nav === true
                    : this.$store.state.permission == x.meta.requiresPermission && x.meta.nav === true
            )
        },
    },
}
</script>

<style lang="scss" scoped>
nav {
    background: $color-white;
    max-width: 240px;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    grid-area: nav;
    display: grid;
    grid-template-rows: auto 1fr auto;
    padding: $spacer;
    color: $color-icecream;
    border-right: 1px solid darken(#d6d5da, 10%);
    box-shadow: 10px 4px 25px rgba($color-maroon, 0.2);

    img {
        max-width: 125px;
        margin: 0 0 24px;
    }

    ul {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin-bottom: $spacer / 2;
            padding: 12px 0;

            &:last-of-type {
                margin-right: 0;
            }

            a {
                color: $color-maroon;
                text-transform: uppercase;
                text-decoration: none;
                font-weight: $semi-bold;
                font-size: rem(14px);
                padding: 12px;
                cursor: pointer;
                transition: color 0.2s ease-in-out, background 0.2s ease-in-out;

                &.router-link-active,
                &:hover {
                    background: $color-cosmiclatte;
                    border-radius: 8px;
                    color: $color-burgundy;
                    transition: color 0.2s ease-in-out, background 0.2s ease-in-out;
                }
            }
        }
    }

    .btn {
        text-align: center;
    }
}
</style>

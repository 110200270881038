<template>
    <div id="app">
        <Menu v-if="session"></Menu>
        <div class="content-wrapper">
            <router-view />
        </div>
    </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import { api } from './api/index'

export default {
    components: {
        Menu,
    },

    async beforeMount() {
        if (localStorage.getItem('access_token') && localStorage.getItem('permission')) {
            try {
                const response = await api.get('/checkAuth', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                        Permission: localStorage.getItem('permission'),
                    },
                })
                if (response.status === 200) {
                    this.$store.commit('SET_TOKEN', localStorage.getItem('access_token'))
                    this.$store.commit('SET_PERMISSION', Number(localStorage.getItem('permission')))
                    if (this.$router.history.current.path == '/') {
                        this.$router.push('/overzicht').catch(() => {})
                    }
                }
            } catch (err) {
                if (err.response.status === 401) {
                    localStorage.clear()
                    this.$router.push('/').catch(() => {})
                    return
                }
            }
        }
    },

    computed: {
        session() {
            return this.$store.state.token
        },
    },
}
</script>

<style lang="scss">
* {
    box-sizing: border-box;
}

#app,
body {
    min-height: 100vh;
    background: #f3f1f5;
    padding: 0;
    margin: 0;
    font-family: $Inter;
    font-size: rem(16px);
    font-weight: $normal;
    color: $color-maroon;
}

#app {
    display: grid;
    grid-template-areas: 'nav content';
    grid-template-columns: 240px 1fr;
    gap: $spacer;

    .content-wrapper {
        max-width: 1400px;
        width: 100%;
        padding: 72px 0 24px;
        margin: 0 auto;
        grid-area: content;

        @include desktop {
            margin: $gutter auto;
            padding: 0 $spacer;
        }
    }

    .page__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            margin: 0;
        }

        & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: $spacer;

            .input__wrapper {
                margin: 0;
            }
        }
    }

    hr {
        margin: 48px auto;

        &.seperator {
            margin: $spacer auto;
        }
    }
}

span.error,
span.success,
span.info {
    background: lighten($color-error, 37%);
    padding: 12px;
    border-radius: 8px;
    display: block;
    margin-top: 24px;
    color: darken($color-error, 30%);
}

span.success {
    background: lighten($color-success, 15%);
    color: darken($color-success, 35%);
}

span.info {
    background: lighten($color-echoblue, 15%);
    color: darken($color-echoblue, 50%);
}
</style>
